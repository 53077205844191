import React from 'react';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/index';

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout bodyClass="page-404">
        <SEO title="404" />
        <Helmet>
          <meta
            name="description"
            content="Not Found"
          />
        </Helmet>
      </Layout>
    );
  }
}

export default NotFoundPage;
